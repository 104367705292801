<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <caption></caption>
    <thead class="text-uppercase text-center">
      <tr>
        <th></th>
        <th scope="col">{{ $t('tablasNutricionales.porcion', {g: 132}) }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>230 Kcal</td>
      </tr>
      <tr>
        <td></td>
        <td>963 Kj</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>22 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.azucares_totales') }}</td>
        <td>7,9 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>13 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>10 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>1,3 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>7,6 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>0,98 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linoleico') }}</td>
        <td>0,72 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_omega_linolenico') }}</td>
        <td>0,12 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.dha_omega_3') }}</td>
        <td>80 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.epa_omega_3') }}</td>
        <td>29 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>0 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>0 mg</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>3,1 g</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="2">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>94 mcg RE</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>0,86 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>4,3 mg alfa TE</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>6,2 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>0,20 g</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>0,20 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>2,3 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>0,79 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>0,25 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>38 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>0,62 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>5,2 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>17 mg</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>39 mg</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>140 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>359 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>145 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>238 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>175 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>23 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>1,8 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>1,2 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>0,20 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>0,27 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fluor') }}</td>
        <td>0,12 mg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>10 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>7,3 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>6,8 mcg</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>20 mcg</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableSustentaDessert",
};
</script>
