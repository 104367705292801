<template>
  <div>
    <Header
      imgUrl="header-nutricionclinicaadulta.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_nca')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_Dessert.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <!-- <Sabor color="#d59952" :texto="$t('productos.saborDulceDeLeche')" /> -->
            <Sabor color="#f19930" :texto="$t('productos.saborDulceDeLeche')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 325 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p
              class="pt-3"
              v-html="$t('productos.sustentaDessert.descripcion')"
            ></p>
          </div>

          <CaracteristicasNutricionales
            :columnas="2"
            :caracteristicas="caracteristicas"
          />

          <div class="row mt-3">
            <div
              class="col-md-5 d-flex justify-content-around align-items-left"
            >
              <div>{{ $t("productos.alergenos") }}</div>
              <div>
                <img
                  :src="getIcon('icono-leche.png')"
                  class="img-fluid alergenos"
                />
              </div>
              <div>
                <img
                  :src="getIcon('icono-soja.png')"
                  class="img-fluid alergenos"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TableSustentaDessert />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <ModoPreparacionImg
        :texto="$t('productos.modoPreparacion.texto')"
        textoOrientativo=""
        imageName="preparacion-dessert.png"
        :medida="
          $t('productos.modoPreparacion.medida', {
            gr: '4,3',
            producto: 'Sustenta® Dessert Dulce de Leche',
          })
        "
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'espesante-resist-video',
        'nutricion-clinica-adultos',
        'modulos-nutricionales',
        'espesante-resist',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TableSustentaDessert from "@/components/tablas/TableSustentaDessert.vue";
import ModoPreparacionImg from "@/components/ModoPreparacionImg.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "SustentaDessert",
  components: {
    Header,
    Sabor,
    CaracteristicasNutricionales,
    TableSustentaDessert,
    ModoPreparacionImg,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.sustentaDessert.caracteristicas,
        es: es.productos.sustentaDessert.caracteristicas,
      }
    };
  },
  methods: {
    getIcon(fileName) {
      return require("../../assets/images/icons/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>