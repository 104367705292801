<template>
  <div class="mt-4 mb-4 modo-preparacion-img">
    <div class="row">
      <div class="pe-5" :class="textoOrientativo == '' ? 'col-md-6' : 'col-md-4'">
        <p>
          {{ texto }}
        </p>
      </div>

      <div class="col-md-4 pe-5" v-if="textoOrientativo">
        <p class="mb-2">{{ $t("productos.modoPreparacion.tituloOrientativo") }}</p>
        <p v-html="textoOrientativo"></p>
      </div>

      <div :class="textoOrientativo == '' ? 'col-md-6' : 'col-md-4'">
        <img :src="getImage(imageName)" class="img-fluid pb-3">
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 d-flex px-0">
        <img
          src="@/assets/images/icons/lavadomanos.png"
          class="img-fluid h60 me-1"
        />
        <p class="align-self-center txt-celeste txt-iconos">
          {{ $t("productos.higiene") }}
        </p>
      </div>
      <div class="col-md-4 d-flex px-0">
        <img v-if="medida !== ''"
          src="@/assets/images/icons/lavadomanos-cuchara.png"
          class="img-fluid h60"
        />
        <p class="align-self-center txt-iconos">
          {{ medida }}
        </p>
      </div>
    </div>
    <div class="row mt-4 text-center">
      <span><em>{{ $t("productos.advertenciasTitulo") }}</em> {{ $t("productos.advertencias") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModoPreparacionImg",
  props: {
    texto: String,
    textoOrientativo: String,
    imageName: String,
    medida: String,
  },
  methods: {
    getImage(fileName) {
      return require("@/assets/images/icons/" + this.$i18n.locale + "/" + fileName);
    },
  },
};
</script>
